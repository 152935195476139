import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { photo, specialSection } from "./InfoWithLeftBkgdImg.module.scss"
import { PillButton } from "../UI/PillButton"
import SectionHeading from "../UI/SectionHeading"
export const fragment = graphql`
  fragment infoWithLeftBkgdImgFragment on WpPage_Flexlayouts_FlexibleLayouts_InfoWithLeftBkgdImg {
    sectionHeading
    headingType
    text
    backgroundImage {
      ...GatsbyImageQuery
    }
    button {
      title
      url
    }
  }
`
const InfoWithLeftBkgdImg = ({
  sectionHeading,
  headingType,
  text,
  button,
  backgroundImage
}) => {
  const image = getImage(backgroundImage?.localFile)

  return (
    <section className={`p-0 bg-purple ${specialSection}`}>
      <Container>
        <Row className={`d-flex pt-4 align-items-end position-relative`}>
          <Col md={6} className="px-md-0 ">
            <div className={` h-100 pt-4 px-xl-5 pb-5 pb-xl-6`}>
              <SectionHeading
                className="pt-2 mb-4"
                type={headingType}
                text={sectionHeading}
              />
              <p
                className={`text-start quicksand-500 pt-5 pb-4`}
                dangerouslySetInnerHTML={{ __html: text }}
              />
              {button && <PillButton
                link={button.url}
                title={button.title}
              />}
            </div>
          </Col>
          <Col md={6}>
            <GatsbyImage
              image={image}
              alt={backgroundImage?.altText}
              className={photo}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default InfoWithLeftBkgdImg
